import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../../components/Common/Layout";
import CompanyLogo from "../../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../../components/Website/ProjectsNavigation";
import ServicePellet from "../../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../../components/Website/ProjectImagesSwiper";

const HousesToShare = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="pt"
      title="Brocode Studio || Houses To Share"
      description="Houses to share"
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Houses to share</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <ServicePellet service="Branding" />
                  <ServicePellet service="Development" />

                  <p class="wow move-up mb--20 d-md-block d-none">
                    Experts promoting new products, penetrating emerging markets
                    and providing specialist advice to clients from corporate
                    organizations, private individuals to royal families;
                    focused on securing the right product at the right price for
                    any occasion.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[8]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 d-md-none d-block mx-0">
              <p class="wow move-up mb--20">
                Experts promoting new products, penetrating emerging markets and
                providing specialist advice to clients from corporate
                organizations, private individuals to royal families; focused on
                securing the right product at the right price for any occasion.
              </p>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col xs={12} md={10} className="mx-auto">
                <Img fluid={imagesData[0]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col xs={12} md={10} className="mx-auto">
                <Img fluid={imagesData[1]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col xs={12} md={10} className="mx-auto">
                <Img fluid={imagesData[2]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col xs={12} md={10} className="mx-auto">
                <Img fluid={imagesData[3]} />
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={imagesData[4]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={imagesData[5]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={imagesData[6]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={imagesData[7]} />
              </Col>
            </Row>
            <ProjectImagesSwiper
              imagesList={[
                imagesData[4],
                imagesData[5],
                imagesData[6],
                imagesData[7],
              ]}
            />
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="housestoshare" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{ name: "Health Coach", link: "/work/healthcoach" }}
            prevProject={{
              name: "Broadway Podcasts",
              link: "/work/broadwayapp",
            }}
            selectedLanguage="pt"
          />
        </div>
      </div>
    </Layout>
  );
};

export default HousesToShare;

export const HousesToShareQuery = graphql`
  query HousesToSharePhotosPt {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/housestoshare/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
